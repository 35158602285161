.nameAndScoreLabel {
    font-size: 12px;
    font-weight: bolder;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.subscriberLevelLabel {
    /*min-width: 125px;*/
    /*margin: 2px;*/
    /*padding: 10px 10px 10px 10px;*/
    /*border-radius: 5px 5px 0px 0px;*/
    /*margin-right: 50px;*/
    font-size: .5rem;
    color: #FFFFFF;
    /*background-color: #282828;*/
    /*font-weight: bolder;*/
    /*display: block;*/
    /*cursor: pointer;*/
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
    /*font-variant: common-ligatures small-caps;*/
}