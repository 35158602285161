.sectionHeader {
    font-size: .8rem;
    color: #999999;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}

.usernameText {
    font-size: 2rem;
    color: #FFFFFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
    text-align: center;
}

.sectionText {
    font-size: 1rem;
    color: #FFFFFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}

.sectionTextSubHeader {
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: bolder;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}

.subscriberLevelLabel {
    font-size: .5rem;
    color: #FFFFFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}