.HeaderText {
    font-size: 13px;
    font-weight: bold;
    align-self: center;
}

.menuHeader {
  font-size: 8px;
}

a {
  margin: 5px;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.closedBetaLabel {
  font-size: .85rem;
  color: #a4a2a2;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-variant-caps: small-caps;
}

.img-gradient {
    position:relative;
  }
  
  
.img-gradient::after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7));
}


.horizontal-gradient {
  background: linear-gradient(to right, red, 30%, black);
  width: 100%;
}

.layered-image {
  background: linear-gradient(to right, red, 30%, transparent),
      url("header_flip.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  font-size: 60px;
  height: 130px;
  font-weight: bold;
  color: white;
}

.layered-image-mobile {
  background: linear-gradient(to right, red, 30%, transparent);
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  height: 130px;
  font-size: 60px;
  font-weight: bold;
  color: white;
}

.leaderboard-title {
  padding-left: 20px;
  padding-top: 10px;
}

.leaderboard-subtitle {
  padding-left: 20px;
  margin-top: -15px;
  font-size: 15px;
  margin-bottom: 25px;
}

.navLinks {
  font-size: .9rem;
  font-weight: bold;
  padding: 2px;
  cursor: pointer;
  /*background-color: #e8c600;*/
}

.navLinks:hover {
  background-color: #1d1d1d;
}

.navLinksSubSection {
  font-size: .9rem;
  font-size: .85rem;
  font-weight: bold;
  padding: 2px;
  cursor: pointer;
  /*color: #b0fdff;*/
  /*background-color: #e8c600;*/
}

.navLinksSubSection:hover {
  background-color: #1d1d1d;
}

.navLinksHeader {
  font-size: .7rem;
  font-weight: bold;
  padding: 2px;
  cursor: pointer;
  margin-top: 5px;
  /*color: #b0fdff;*/
  /*background-color: #e8c600;*/
}

.navLinksHeader:hover {
  background-color: #1d1d1d;
}
