.deckHeaderLeft {
    /*min-width: 500px;*/
    width: max-content;
}

.deckHeaderRight {
    /*min-width: 600px;*/
    /*float: right;*/
}

.recharts-wrapper {
    /*position: absolute;*/
}

@media all and (min-width: 1000px) {
    .stickySideBarContainer {
        position: -webkit-sticky;
        position: sticky;
        top: 73px;
        /*z-index: 2000;*/
    }
}

.stickyTabBarContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 73px;
    background: #0e0e0e;
    background-color: #0e0e0e;
    z-index: 1000;
}


.tabViewContainer {
    /*width: 100%;*/
    /*width: -moz-available;          !* WebKit-based browsers will ignore this. *!*/
    /*width: -webkit-fill-available;  !* Mozilla-based browsers will ignore this. *!*/
    /*width: fill-available;*/
    /*max-width: 600px;*/
}

.previewContentScrollContainer {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #1d1d1d;
}

.pieChartContainer {
    background-color: black;
    border-bottom-style: solid;
    border-color: #2f2f2f;
}

.barChartContainer {
    /*border-bottom-style: solid;*/
    /*border-color: #2f2f2f;*/
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.reportCardItemWrapper {
    display: block;
    cursor: pointer;
}

.subCategoriesComponentLabel {
    min-width: 125px;
    margin: 2px;
    padding: 10px 0px 10px 10px;
    font-size: .65rem;
    color: #FFFFFF;
    background-color: #282828;
    display: block;
    cursor: pointer;
    width: 100%;
}

.subCategoriesComponentLabelSelected {
    min-width: 125px;
    margin: 2px;
    padding: 10px 0px 10px 10px;
    font-size: .65rem;
    color: #FFFFFF;
    background-color: #525252;
    display: block;
    width: 100%;
}

.subCategoriesComponentLabel:hover {
    background-color: #6e6e6e;
}

.categoriesComponentLabel {
    min-width: 125px;
    margin: 2px;
    padding: 10px 0px 10px 10px;
    font-size: .7rem;
    font-weight: bold;
    color: #ffd06a;
    background-color: #1c1c1c;
    display: block;
    cursor: pointer;
    width: 100%;
}

.categoriesComponentLabelSelected {
    min-width: 125px;
    margin: 2px;
    padding: 10px 0px 10px 10px;
    font-size: .7rem;
    font-weight: bold;
    color: #FFD06AFF;
    background-color: #525252;
    display: block;
    width: 100%;
}

.categoriesComponentLabel:hover {
    background-color: #424242;
}

.TitleText {
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    align-self: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}
.SaltRating {
    font-size: 19px;
    font-weight: bolder;
    white-space: pre;
}

.previewUserAvatarThumb {
    /*width: clamp(12vw, 16vw, 4rem);*/
    object-fit: fill;
}

.previewDeckTitleLabel {
    font-size: 2em;
    /*font-size: 24px;*/
    font-weight: bolder;
    color: #e8c600;
}

.previewDeckCommandersLabel {
    margin-top: 10px;
    font-size: clamp(.8em, 1rem, 1vw);
    font-weight: bold;
}

.previewDeckAuthorLabel {
    font-size: 1rem;
    /*font-size: 12px;*/
    white-space: pre;
    /*cursor: pointer;*/
    color: #fffaef;
    font-weight: bolder;

}

.previewDeckAuthorLabel:hover {
    font-size: 1rem;
    /*font-size: 12px;*/
    white-space: pre;
    /*cursor: pointer;*/
    color: #ff9000;
    font-weight: bolder;
}

.previewDeckCommandersText {
    /*font-size: clamp(.8em, 1rem, 1vw);*/
    font-size: 1rem;
    /*font-size: 12px;*/
    white-space: pre;
    cursor: pointer;
    color: #fcf0a5;
    font-weight: bold;
}


/*.previewDeckCommandersText:hover {*/
/*    color: #ff9000;*/
/*}*/

.previewPowerLevelChangedLabel {
    margin-top:15px;
    font-size: 11px;
    cursor: pointer;
    color: #fad104;
    font-weight: bolder;
}

.bracketHeaderLabel {
    margin-top:15px;
    cursor: pointer;
    /*color: #fad104;*/
    font-weight: bolder;
    font-size: 1rem;
}

.breadCrumbButton {
    background-color: #000000;
    cursor: pointer;
}

.breadCrumbButton:hover {
    background-color: #303030;
    cursor: pointer;
}

.bracketSection {
    /*color: #fad104;*/
    min-width: 100px;
}

.bracketSectionEndCaps {
    /*color: #fad104;*/
    margin-top: -10px;
    font-weight: bolder;
    font-size: 3.5rem;
}

.bracketSectionHeaders {
    font-size: .7rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}

.bracketSectionSubHeaders {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}

.bracketSectionValue {
    font-size: 2rem;
    font-weight: bolder;
    font-family:'Courier New', Courier, monospace;
    text-align: center;
    margin-top: 0;
}


.previewPowerLevelChangedLabel:hover {
    text-decoration-line: underline;
}

.reportCardHeader {
    font-size: .9rem;
    font-weight: bolder;
    text-align: center;
    background-color: rgb(66, 106, 201);
    text-shadow: 2px 2px 3px #000000;
    width: 100%;
}

.reviewContainer {
    /*width: 480px;*/
    /*max-width: 380px;*/
    width: 100%;
    /*height: 260px;*/
    background: #FFFFFF url("review_background.png") no-repeat left top;
}

.perceivedLabelHeader {
    font-size: .9rem;
    /*font-weight: bolder;*/
    /*text-align: center;*/
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    /*background-color: rgb(66, 106, 201);*/
    /*text-shadow: 2px 2px 3px #000000;*/
}

.perceivedLabel {
    margin-top: 10px;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    /* color:crimson; */
    /* align-self: center; */
}

.reportCardSectionTitles {
    font-size: .75rem;
    font-weight: bolder;
    text-align: center;
}

.reportCardSectionTitlesDisabled {
    font-size: .75rem;
    font-weight: bolder;
    text-align: center;
    color: #868686;
}

.reportCardGradeLetter {
    font-size: 2rem;
    font-weight: bolder;
    font-family:'Courier New', Courier, monospace;
    text-align: center;
}

.reportCardScore {
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: center;
    /* color:crimson; */
    /* align-self: center; */
}

.chefsRemarksText {
    font-size: 1.4rem;
    font-weight: bolder;
    color:rgb(108, 245, 255);
    text-align: center;
    font-style: italic;
    padding-right: 20px;
    padding-left: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.SaltScoreHeader {
    padding-left: 0px;
    font-size: 12px;
    font-weight: bolder;
    /* align-self: center; */
}

.AuthorText {
    font-size: 10px;
    font-weight: bold;
    align-self: center;
}

.previewTabLabel {
    font-size: 1em;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
    /*align-self: flex-start;*/
    /*margin-right: 10px;*/
}

.recharts-cartesian-axis-tick-value {
    fill: #fff8a1;
    font-size: .7rem;
    margin-top: 10px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /*width: 200px;*/
}

.barChartXAxisLabel {
    /*display:block;*/
    /*z-index: 9999;*/
    color: yellow;
    background-color: #0d66d0;
    text-align: right;
    /*max-width: 100px;*/
    /*width: 100px;*/
    /*min-width: 50px;*/
    font-size: 10px;
    font-weight: bold;
    /*position: absolute;*/

    /*-webkit-transform: rotate(-45deg);*/
    /*-moz-transform: rotate(-45deg);*/
    /*filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);*/
}

.previewTabContainer {
    /*min-width: 100px;*/
    /*margin-left: 10px;*/
    /*margin-right: 5px;*/
    /*margin-top: 10px;*/
    /*overflow: hidden;*/
    white-space: nowrap;
    /*margin-right: calc((clamp(-1px, 100vw, 600px) / 6))*/
    /*width: calc(clamp(calc(400px / 3), calc(calc(100vw - 30px) / 3), calc(800px / 3)) - 50px)*/
}

.PreviewContainerHeader {
    background: linear-gradient(to right, rgb(72, 83, 102), 30%, transparent);
    background-repeat: no-repeat;
    background-position: right;
    color: white;
}

.PreviewContainer {
    background-color: #121212;
    /*width: clamp(400px, calc(100vw - 30px), 800px);*/
    height: calc(100vh - 30px);
    padding: 10px;
    width: 100vw;
}

.PreviewModal {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    /* background: -moz-linear-gradient(top, #28c4e3 0%, #0d5664 100%); */
    /* text-shadow: 0 1px 2px #fff; */
    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#28c4e3), color-stop(100%,#0d5664));  */
    /* webkit */font-size:20px
}

.filters-header {
    padding-left: 0px;
    font-size: 11px;
    font-weight: bolder;
    margin-top: 2px;
    white-space: nowrap;
    text-overflow: unset;
}

.previewDetailTableCell {
    /*background-color: #7cc33f;*/
    white-space: pre-line;
    display: block;
    word-wrap: normal;
    font-size: 15px;
    vertical-align: middle;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.panelText {
    /*background-color: #7cc33f;*/
    word-wrap: normal;
    font-size: 15px;
    vertical-align: middle;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* .PreviewContainer:hover {
    -moz-box-shadow:    0 -1px 2px #fff, 0 1px 1px #666, inset 0 -1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.8);
    -webkit-box-shadow: 0 -1px 2px #fff, 0 1px 1px #666, inset 0 -1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.8);
    box-shadow:         0 -1px 2px #fff, 0 1px 1px #666, inset 0 -1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.8);
    border: solid #ccc 3px;
} */

.perceivedPowerLevelScore {
    animation-name: pulsate;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

.nutritionFactsLabelBoxBorder {
    border-style: solid;
    border-width: 3px;
    border-color: #FFFFFF;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
}

.nutritionFactsLabelBoxHeader {
    font-family: "Helvetica";
    font-size: 2.5rem;
    font-weight: bold;
}

.nutritionFactsLabelBoxContents {
    font-family: "Helvetica";
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    white-space: nowrap;
    font-weight: bolder;
}

.nutritionFactsLabelBoxDailyValue {
    align-self: end;
    text-align: right;
}

.nutritionFactsLabelBoxPercentage {
    align-self: end;
    text-align: right;
    width: 100%;
}

.nutritionFactsLabelBoxAmount {
    font-weight: normal;
    margin-left: 10px;
}

@keyframes pulsate {
    0% {
        opacity: 30%
    }

    50% {
        opacity: 70%
    }

    100% {
        opacity: 100%
    }
}